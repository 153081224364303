import menu from "./components/menu"
import feedback from "./components/feedback"
import selectTheme from "./components/selectTheme";
import customSelect from "./components/customSelect";
import togglePreloader from "./components/togglePreloader";

menu()
customSelect()
selectTheme()
togglePreloader()

document.querySelectorAll('.js-form').forEach(form => feedback(form))
