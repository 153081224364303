const togglePreloader = () => {
    const {body} = document
    const preloader = document.querySelector('.preloader')

    if (preloader) {
        body.classList.add('is-overflow-hidden')

        window.addEventListener('load', () => {
            preloader.classList.add('preloader--hidden')
            body.classList.remove('is-overflow-hidden')
        })
    }
}

export default togglePreloader
