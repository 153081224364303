const feedback = (form) => {
    // const endpoint = 'https://stage-pay-site.humoney.com/uk/forms/feedback'; // endpoint for developing feedback
    // const endpoint = "https://stage-pay-site.humoney.com/uk/forms/contact-form"; // endpoint for developing contact
    // const endpoint = "https://ss-site.di3m.com.ua/en/forms/contact-form";

    const formType = form.attributes['data-type'].value
    const formDefaultClassName = form.attributes.class.value
    const {location} = document
    const {origin} = location
    const localization = location.pathname.substring(1, 3) || 'uk'
    const endpoint = `${origin}/${localization}/forms/${formType}`;
    const allTextFields = [...form.querySelectorAll('.js-textfield')];
    const checkbox = form.querySelector('.js-checkbox');
    const checkboxInput = checkbox.querySelector('input');
    const submitBtn = form.querySelector('.js-form-submit')

    const setInputErrorClass = input => input.classList.add('is-error');
    const removeInputErrorsClass = item => item.classList.remove('is-error');
    const toggleButtonDisabled = button => button.classList.toggle('is-disabled');

    const setFormStatusLoading = () => form.classList.add('is-loading');
    const removeFormStatusLoading = () => form.classList.remove('is-loading');
    const setFormStatusLoaded = () => form.classList.add('is-loaded');
    const setFormStatusFailed = () => form.classList.add('is-failed');

    const resetAllInputs = () => {
        allTextFields.forEach(input => {
            removeInputErrorsClass(input);
        });
    };

    const resetAllErrors = () => {
        [...document.querySelectorAll('.js-error')].forEach(input => input.remove());
    };

    const resetForm = () => {
        resetAllErrors()

        setTimeout(() => {
            form.reset();
            // eslint-disable-next-line no-param-reassign
            form.className = formDefaultClassName;
        }, 5000)
    };

    const createErrorItem = (closestInput, content) => {
        closestInput.insertAdjacentHTML(
            'beforeend',
            `
          <span class='textfield__error js-error'>
            ${content}
          </span>
      `
        );
    };

    checkbox.addEventListener('click', () => {
        if (checkbox.classList.contains('is-error')) {
            checkbox.classList.remove('is-error')
        }
    })

    form.addEventListener('submit', (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)

        if (checkboxInput.checked) {
            resetAllInputs()
            resetAllErrors()
            setFormStatusLoading()
            toggleButtonDisabled(submitBtn)

            if (!formData.has('subject')) {
                formData.append('subject', 'no-subject')
            }

            if (formData.has('is_receive_newsletter')) {
                formData.set('is_receive_newsletter', 1)
            }

            fetch(endpoint, {
                method: 'POST',
                body: formData,
            })
                .then(res => {
                    if (res.ok) {
                        setFormStatusLoaded();
                        resetForm();
                    }
                    return res.json()
                })
                .then(({violations}) => {
                    removeFormStatusLoading();

                    if (violations) {
                        violations.forEach(({propertyPath, title}) => {
                            allTextFields.forEach((textField) => {
                                const input = textField.querySelector("input");

                                if (propertyPath === input.name) {
                                    setInputErrorClass(textField);
                                    createErrorItem(textField, title);
                                }
                            });
                        });
                    }
                })
                .catch(() => {
                    setFormStatusFailed()
                    resetForm()
                })
                .finally(() => {
                    toggleButtonDisabled(submitBtn)
                })
        } else {
            checkbox.classList.add('is-error')
        }
    })
}

export default feedback;
